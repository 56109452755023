import React from 'react';
import {Grid, Text} from '@nextui-org/react';
import Divider from '@mui/material/Divider';
import { Nav } from '../../components/navbar/navbar';
import Footer from '../../components/footer';


export default  function TermsView() { 
  return (
    <div >
      <Nav navLink='/terms'/>
      <Grid.Container css={{justifyContent:'center'}}>
      <div >
        <Text h1 >
          Terms of Service
        </Text>
        <div >
          <Divider/>
        </div>
        <Text h2>
          General Terms
        </Text>
        <Text size={20}>
          By accessing and placing an order with Malia, you confirm that you are in agreement with and bound by the<br/>
          terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire website<br/>
          and any email or other type of communication between you and Malia.<br/>
        </Text>
        <Text size={20}>
          Under no circumstances shall Malia team be liable for any direct, indirect, special, incidental or consequential<br/>
          damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the<br/>
          materials on this site, even if Malia team or an authorized representative has been advised of the possibility of<br/>
          such damages. If your use of materials from this site results in the need for servicing, repair or correction <br/>
          equipment or data, you assume any costs thereof.<br/>
        </Text>
        <Text size={20}>
          Malia will not be responsible for any outcome that may occur during the course of usage of our resources. We<br/>
          reserve the rights to change prices and revise the resources usage policy in any moment.<br/><br/>
        </Text>
        <Text h2>
          License
        </Text>
        <Text size={20}>
          Malia grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the<br/>
          app strictly in accordance with the terms of this Agreement.<br/>
        </Text>
        <Text >
          These Terms & Conditions are a contract between you and Malia (referred to in these Terms & Conditions as<br/>
          "Malia", "us", "we" or "our"), the provider of the Malia website and the services accessible from the Malia<br/>
          website (which are collectively referred to in these Terms & Conditions as the "Malia Service").<br/>
        </Text>
        <Text size={20}>
          You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions,<br/>
          please do not use the Malia Service. In these Terms & Conditions, "you" refers both to you as an individual<br/>
          and to the entity you represent. If you violate any of these Terms & Conditions, we reserve the right to cancel<br/>
          materials on this site, even if Malia team or an authorized representative has been advised of the possibility of<br/>
          equipment or data, you assume any costs thereof.<br/>
        </Text>
        <Text >
          You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions,<br/>
          please do not use the Malia Service. In these Terms & Conditions, "you" refers both to you as an individual<br/>
          and to the entity you represent. If you violate any of these Terms & Conditions, we reserve the right to cancel<br/>
          equipment or data, you assume any costs thereof.<br/><br/>
        </Text>
        <Text h2>
          Definitions and key terms
        </Text>
        <Text >
          To help explain things as clearly as possible in this Terms & Conditions, every time any of these terms are<br/>
          referenced, are strictly defined as:<br/>
          <li >
            Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify<br/>
            your browser, provide analytics, remember information about you such as your language preference or login<br/>
            information.<br/><br/>
          </li>
          <li >
            Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to ScaleUp, HACI İSA<br/>
            MAHALLESİ 75.YIL CUMHURİYET CAD. NO: 5 İÇ KAPI NO: 2 URLA / İZMİR that is responsible for your<br/>
            information under this Terms & Conditions.<br/><br/>
          </li>
          <li>
            Country: where Malia or the owners/founders of Malia are based, in this case is TR<br/><br/>
          </li>
          <li>
            Device: any internet connected device such as a phone, tablet, computer or any other device that can be<br/>
            used to visit Malia and use the services.<br/><br/>
          </li>
          <li>
            Service: refers to the service provided by Malia as described in the relative terms (if available) and on this<br/>
            platform.<br/><br/>
          </li>
          <li>
            Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others<br/>
            who provide our content or whose products or services we think may interest you.<br/><br/>
          </li>
          <li>
            Website: Malia's site, which can be accessed via this URL:<br/><br/>
          </li>
          <li>
            You: a person or entity that is registered with Malia to use the Services.<br/><br/>
          </li>
        </Text>
        <Text h2>
          Restrictions
        </Text>
        <Text size={20}>
          You agree not to, and you will not permit others to:<br/><br/>
          <li>
            License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially<br/>
            exploit the app or make the platform available to any third party.<br/><br/>
          </li>
          <li>
            Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the<br/>
            app.<br/><br/>
          </li>
          <li>
            Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Malia or<br/>
            its affiliates, partners, suppliers or the licensors of the app.<br/><br/>
          </li>
        </Text>
        <Text h2>
          Return and Refund Policy
        </Text>
        <Text size={20}>
          Thanks for shopping at Malia. We appreciate the fact that you like to buy the stuff we build. We also want to<br/>
          make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our products.<br/><br/>
        </Text>
        <Text size={20}>
          As with any shopping experience, there are terms and conditions that apply to transactions at Malia. We’ll be<br/>
          as brief as our attorneys will allow. The main thing to remember is that by placing an order or making a<br/>
          purchase at Malia, you agree to the terms along with Malia's Privacy Policy.<br/><br/>
        </Text>
        <Text size={20}>
          If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to<br/>
          contact us and we will discuss any of the issues you are going through with our product.<br/><br/>
        </Text>
        <Text h2>
          Your Suggestions
        </Text>
        <Text size={20}>
          Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to<br/>
          Malia with respect to the app shall remain the sole and exclusive property of Malia.<br/><br/>
        </Text>
        <Text size={20}>
          Malia shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any<br/>
          way without any credit or any compensation to you.<br/><br/>
        </Text>
        <Text h2>
          Your Consent
        </Text>
        <Text size={20}>
          We've updated our Terms & Conditions to provide you with complete transparency into what is being set<br/>
          when you visit our site and how it's being used. By using our app, registering an account, or making a<br/>
          purchase, you hereby consent to our Terms & Conditions.<br/><br/>
        </Text>
        <Text h2>
          Links to Other Websites
        </Text>
        <Text size={20}>
          This Terms & Conditions applies only to the Services. The Services may contain links to other websites not<br/>
          operated or controlled by Malia. We are not responsible for the content, accuracy or opinions expressed in<br/>
          such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by<br/>
          us. Please remember that when you use a link to go from the Services to another website, our Terms &<br/>
          Conditions are no longer in effect. Your browsing and interaction on any other website, including those that<br/>
          have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use<br/>
          their own cookies or other methods to collect information about you.<br/><br/>
        </Text>
        <Text h2>
          Cookies
        </Text>
        <Text size={20}>
          Malia uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of<br/>
          data stored on your computer or mobile device by your web browser. We use Cookies to enhance the<br/>
          performance and functionality of our app but are non-essential to their use. However, without these cookies,<br/>
          certain functionality like videos may become unavailable or you would be required to enter your login details<br/>
          every time you visit the app as we would not be able to remember that you had logged in previously. Most<br/>
          web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able<br/>
          to access functionality on our website correctly or at all. We never place Personally Identifiable Information in<br/>
          Cookies.<br/><br/>
        </Text>
        <Text h2>
          Changes To Our Terms & Conditions
        </Text>
        <Text size={20}>
          You acknowledge and agree that Malia may stop (permanently or temporarily) providing the Service (or any<br/>
          features within the Service) to you or to users generally at Malia's sole discretion, without prior notice to you.<br/>
          You may stop using the Service at any time. You do not need to specifically inform Malia when you stop using<br/>
          the Service. You acknowledge and agree that if Malia disables access to your account, you may be prevented<br/>
          from accessing the Service, your account details or any files or other materials which is contained in your<br/>
          account.<br/><br/>
        </Text>
        <Text size={20}>
          If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update the<br/>
          Terms & Conditions modification date below.<br/><br/>
        </Text>
        <Text h2>
          Modifications to Our app
        </Text>
        <Text size={20}>
          Malia reserves the right to modify, suspend or discontinue, temporarily or permanently, the app or any service<br/>
          to which it connects, with or without notice and without liability to you.<br/><br/>
        </Text>
        <Text h2>
          Updates to Our app
        </Text>
        <Text size={20}>
          Malia may from time to time provide enhancements or improvements to the features/ functionality of the<br/>
          app, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").<br/><br/>
        </Text>
        <Text size={20}>
          Updates may modify or delete certain features and/or functionalities of the app. You agree that Malia has no<br/>
          obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or<br/>
          functionalities of the app to you.<br/><br/>
        </Text>
        <Text size={20}>
          You further agree that all Updates will be (i) deemed to constitute an integral part of the app, and (ii) subject<br/>
          to the terms and conditions of this Agreement.<br/><br/>
        </Text>
        <Text h2>
          Third-Party Services
        </Text>
        <Text size={20}>
          We may display, include or make available third-party content (including data, information, applications and<br/>
          other products services) or provide links to third-party websites or services ("Third-Party Services").<br/><br/>
        </Text>
        <Text size={20}>
          You acknowledge and agree that Malia shall not be responsible for any Third-Party Services, including their<br/>
          accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other<br/>
          aspect thereof. Malia does not assume and shall not have any liability or responsibility to you or any other<br/>
          person or entity for any Third-Party Services.<br/><br/>
        </Text>
        <Text size={20}>
          Third-Party Services and links thereto are provided solely as a convenience to you and you access and use<br/>
          them entirely at your own risk and subject to such third parties' terms and conditions.<br/><br/>
        </Text>
        <Text h2>
          Term and Termination
        </Text>
        <Text size={20}>
          This Agreement shall remain in effect until terminated by you or Malia.<br/><br/>
          Malia may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement<br/>
          with or without prior notice.<br/><br/>
          This Agreement will terminate immediately, without prior notice from Malia, in the event that you fail to<br/>
          comply with any provision of this Agreement. You may also terminate this Agreement by deleting the app and<br/>
          all copies thereof from your computer.<br/><br/>
          Upon termination of this Agreement, you shall cease all use of the app and delete all copies of the app from<br/>
          your computer.<br/><br/>
          Termination of this Agreement will not limit any of Malia's rights or remedies at law or in equity in case of<br/>
          breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.<br/><br/>
        </Text>
        <Text h2>
          Copyright Infringement Notice
        </Text>
        <Text size={20}>
          If you are a copyright owner or such owner’s agent and believe any material on our app constitutes an<br/>
          infringement on your copyright, please contact us setting forth the following information: (a) a physical or<br/>
          electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of<br/>
          the material that is claimed to be infringing; (c) your contact information, including your address, telephone<br/>
          number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not<br/>
          authorized by the copyright owners; and (e) the a statement that the information in the notification is<br/>
          accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.<br/><br/>
        </Text>
        <Text h2>
          Indemnification
        </Text>
        <Text size={20}>
          You agree to indemnify and hold Malia and its parents, subsidiaries, affiliates, officers, employees, agents,<br/>
          partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due<br/>
          to or arising out of your: (a) use of the app; (b) violation of this Agreement or any law or regulation; or (c)<br/>
          violation of any right of a third party.<br/><br/>
        </Text>
        <Text h2>
          No Warranties
        </Text>
        <Text size={20}>
          The app is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of<br/>
          any kind. To the maximum extent permitted under applicable law, Malia, on its own behalf and on behalf of its<br/>
          affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether<br/>
          express, implied, statutory or otherwise, with respect to the app, including all implied warranties of<br/>
          merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise ou<br/>
          of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,<br/>
          Malia provides no warranty or undertaking, and makes no representation of any kind that the app will meet<br/>
          your requirements, achieve any intended results, be compatible or work with any other software, , systems or<br/>
          services, operate without interruption, meet any performance or reliability standards or be error free or that<br/>
          any errors or defects can or will be corrected.<br/><br/>
          Without limiting the foregoing, neither Malia nor any Malia's provider makes any representation or warranty<br/>
          of any kind, express or implied: (i) as to the operation or availability of the app, or the information, content,<br/>
          and materials or products included thereon; (ii) that the app will be uninterrupted or error-free; (iii) as to the<br/>
          accuracy, reliability, or currency of any information or content provided through the app; or (iv) that the app,<br/>
          its servers, the content, or e-mails sent from or on behalf of Malia are free of viruses, scripts, trojan horses,<br/>
          <br/><br/>          
          Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the<br/>
          applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not<br/>
          apply to you.<br/><br/>
        </Text>
        <Text h2>
          Limitation of Liability
        </Text>
        <Text size={20}>
          Notwithstanding any damages that you might incur, the entire liability of Malia and any of its suppliers under<br/>
          any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the<br/>
          amount actually paid by you for the app.<br/><br/>
          To the maximum extent permitted by applicable law, in no event shall Malia or its suppliers be liable for any<br/>
          special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for<br/>
          loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of<br/>
          privacy arising out of or in any way related to the use of or inability to use the app, third-party software<br/>
          and/or third-party hardware used with the app, or otherwise in connection with any provision of this<br/>
          Agreement), even if Malia or any supplier has been advised of the possibility of such damages and even if the<br/>
          remedy fails of its essential purpose.<br/><br/>
          Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so<br/>
          the above limitation or exclusion may not apply to you.<br/><br/>
        </Text>
        <Text h2>
          Severability
        </Text>
        <Text size={20}>
          If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and<br/>
          interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable<br/>
          law and the remaining provisions will continue in full force and effect.<br/><br/>
          This Agreement, together with the Privacy Policy and any other legal notices published by Malia on the<br/>
          Services, shall constitute the entire agreement between you and Malia concerning the Services. If any<br/>
          provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such<br/>
          provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full<br/>
          force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of<br/>
          such term or any other term, and Malia's failure to assert any right or provision under this Agreement shall not<br/>
          constitute a waiver of such right or provision. YOU AND Malia AGREE THAT ANY CAUSE OF ACTION ARISING<br/>
          OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF<br/>
          ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.<br/><br/>
        </Text>
        <Text h2>
          Waiver
        </Text>
        <Text size={20}>
          Except as provided herein, the failure to exercise a right or to require performance of an obligation under this<br/>
          Agreement shall not effect a party's ability to exercise such right or require such performance at any time<br/>
          thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.<br/><br/>
          o failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this<br/>
          Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right<br/>
          or power under this Agreement preclude further exercise of that or any other right granted herein. In the<br/>
          event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this<br/>
          Agreement shall govern.<br/><br/>
        </Text>
        <Text h2>
          Amendments to this Agreement
        </Text>
        <Text size={20}>
          Malia reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is<br/>
          material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a<br/>
          material change will be determined at our sole discretion.<br/><br/>
          By continuing to access or use our app after any revisions become effective, you agree to be bound by the<br/>
          revised terms. If you do not agree to the new terms, you are no longer authorized to use Malia.<br/><br/>
        </Text>
        <Text h2>
          Entire Agreement
        </Text>
        <Text size={20}>
          The Agreement constitutes the entire agreement between you and Malia regarding your use of the app and<br/>
          supersedes all prior and contemporaneous written or oral agreements between you and Malia.<br/>
          You may be subject to additional terms and conditions that apply when you use or purchase other Malia's<br/>
          services, which Malia will provide to you at the time of such use or purchase.<br/><br/>
        </Text>
        <Text h2>
          Updates to Our Terms
        </Text>
        <Text size={20}>
          We may change our Service and policies, and we may need to make changes to these Terms so that they<br/>
          accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example,<br/>
          through our Service) before we make changes to these Terms and give you an opportunity to review them<br/>
          before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms.<br/>
          If you do not want to agree to these or any updated Terms, you can delete your account.<br/><br/>
        </Text>
        <Text h2>
          Intellectual Property
        </Text>
        <Text size={20}>
          The app and its entire contents, features and functionality (including but not limited to all information,<br/>
          software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are<br/>
          owned by Malia, its licensors or other providers of such material and are protected by TR and international<br/>
          copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The<br/>
          material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part,<br/>
          without the express prior written permission of Malia, unless and except as is expressly provided in these<br/>
          Terms & Conditions. Any unauthorized use of the material is prohibited.<br/><br/>
        </Text>
        <Text h2>
          Agreement to Arbitrate
        </Text>
        <Text size={20}>
          This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS FOR<br/>
          INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Malia's<br/>
          INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other controversy<br/>
          between you and Malia concerning the Services or this agreement, whether in contract, warranty, tort, statute,<br/>
          regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest possible<br/>
          meaning allowable under law.<br/><br/>
        </Text>
        <Text h2>
          Notice of Dispute
        </Text>
        <Text size={20}>
          In the event of a dispute, you or Malia must give the other a Notice of Dispute, which is a written statement<br/>
          that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the<br/>
          dispute, and the relief requested. You must send any Notice of Dispute via email to: support@Maliaapp.ai<br/>
          Malia will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email<br/>
          address. You and Malia will attempt to resolve any dispute through informal negotiation within sixty (60) days<br/>
          from the date the Notice of Dispute is sent. After sixty (60) days, you or Malia may commence arbitration.<br/><br/>
        </Text>
        <Text h2>
          Binding Arbitration
        </Text>
        <Text size={20}>
          If you and Malia don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute will<br/>
          be conducted exclusively by binding arbitration as described in this section. You are giving up the right to<br/>
          litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute<br/>
          shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American<br/>
          Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of<br/>
          competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of<br/>
          arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing party<br/>
          shall be borne by the non-prevailing party.<br/><br/>
        </Text>
        <Text h2>
          Submissions and Privacy
        </Text>
        <Text size={20}>
          In the event that you submit or post any ideas, creative suggestions, designs, photographs, information,<br/>
          advertisements, data or proposals, including ideas for new or improved products, services, features,<br/>
          technologies or promotions, you expressly agree that such submissions will automatically be treated as non-<br/>
          confidential and non-proprietary and will become the sole property of Malia without any compensation or<br/>
          credit to you whatsoever. Malia and its affiliates shall have no obligations with respect to such submissions or<br/>
          posts and may use the ideas contained in such submissions or posts for any purposes in any medium in<br/>
          perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services<br/>
          using such ideas.<br/><br/>
        </Text>
        <Text h2>
          Promotions
        </Text>
        <Text size={20}>
          Malia may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”)<br/>
          that require you to submit material or information concerning yourself. Please note that all Promotions may<br/>
          be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age<br/>
          and geographic location. You are responsible to read all Promotions rules to determine whether or not you<br/>
          are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all<br/>
          Promotions Rules.<br/><br/>
          Additional terms and conditions may apply to purchases of goods or services on or through the Services,<br/>
          which terms and conditions are made a part of this Agreement by this reference.<br/><br/>
        </Text>
        <Text h2>
          Typographical Errors
        </Text>
        <Text size={20}>
          In the event a product and/or service is listed at an incorrect price or with incorrect information due to<br/>
          typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or<br/>
          service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not<br/>
          the order has been confirmed and your credit card charged. If your credit card has already been charged for<br/>
          the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or<br/>
          other payment account in the amount of the charge.<br/><br/>
        </Text>
        <Text h2>
          Miscellaneous
        </Text>
        <Text size={20}>
          If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions<br/>
          to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Any<br/>
          waiver of any provision of these Terms & Conditions will be effective only if in writing and signed by an<br/>
          authorized representative of Malia. Malia will be entitled to injunctive or other equitable relief (without the<br/>
          obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. Malia<br/>
          operates and controls the Malia Service from its offices in TR. The Service is not intended for distribution to or<br/>
          use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to<br/>
          law or regulation. Accordingly, those persons who choose to access the Malia Service from other locations do<br/>
          so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local<br/>
          laws are applicable. These Terms & Conditions (which include and incorporate the Malia Privacy Policy)<br/>
          contains the entire understanding, and supersedes all prior understandings, between you and Malia<br/>
          concerning its subject matter, and cannot be changed or modified by you. The section headings used in this<br/>
          Agreement are for convenience only and will not be given any legal import.<br/><br/>
        </Text>
        <Text h2>
          Disclaimer
        </Text>
        <Text size={20}>
          Malia is not responsible for any content, code or any other imprecision.<br/><br/>
          Malia does not provide warranties or guarantees.<br/><br/>
          In no event shall Malia be liable for any special, direct, indirect, consequential, or incidental damages or any<br/>
          damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in<br/>
          connection with the use of the Service or the contents of the Service. The Company reserves the right to make<br/>
          additions, deletions, or modifications to the contents on the Service at any time without prior notice.<br/>
          The Malia Service and its contents are provided "as is" and "as available" without any warranty or<br/>
          representations of any kind, whether express or implied. Malia is a distributor and not a publisher of the<br/>
          content supplied by third parties; as such, Malia exercises no editorial control over such content and makes no<br/>
          warranty or representation as to the accuracy, reliability or currency of any information, content, service or<br/>
          merchandise provided through or accessible via the Malia Service. Without limiting the foregoing, Malia<br/>
          specifically disclaims all warranties and representations in any content transmitted on or in connection with<br/>
          the Malia Service or on sites that may appear as links on the Malia Service, or in the products provided as a<br/>
          part of, or otherwise in connection with, the Malia Service, including without limitation any warranties of<br/>
          merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or<br/>
          written information given by Malia or any of its affiliates, employees, officers, directors, agents, or the like will<br/>
          create a warranty. Price and availability information is subject to change without notice. Without limiting the<br/>
          foregoing, Malia does not warrant that the Malia Service will be uninterrupted, uncorrupted, timely, or error-free.

        </Text>
        </div>
      </Grid.Container >
      <Footer/> 
    </div>
  )
}