import axios from 'axios';
//import * as dotenv from "dotenv";
//import { store } from '../redux/store';
//import { clearUser } from '../redux/slices/auth';

//dotenv.config();

const axiosService = axios.create({
  baseURL: `https://us-central1-chat-d2b1c.cloudfunctions.net/api`,
  timeout: 16000,
  headers: {
    "X-AUTH-TOKEN": `bGVmYWNpbGl0YXRldXI6bHoyQEdON2NDQDhASDklblUkbUdy`
  }
});

// Config
const TOKEN_PAYLOAD_KEY = 'authorization';
const AUTH_TOKEN = 'token';
const AUTH_TOKEN_TYPE = 'Bearer';

// API Request interceptor
axiosService.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem(AUTH_TOKEN);
    if (access_token) {
      config.headers[TOKEN_PAYLOAD_KEY] = AUTH_TOKEN_TYPE + ' ' + access_token;
    }
    if (config.method === 'get') {
      //config.params = { lang: i18n.language, ...config.params };
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    /*notification.error({
      message: 'Error',
    });*/
    console.log(error);
    Promise.reject(error);
  }
);

// API respone interceptor
axiosService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const notificationParam = {
      message: error.response.data.statusCode,
      ...error
    };

    // Remove token and redirect
    if (error.response.status === 403 || error.response.status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      //store.dispatch(clearUser());
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    }

    if (error.response.status === 500) {
      notificationParam.message = error.response.data.message;
    }
    //notification.error(notificationParam);
    console.log(notificationParam);
    return Promise.reject(error);
  }
);

export default axiosService;
