import {Button, Divider, Grid, Image, Text, Link} from '@nextui-org/react';
import React from 'react';
import { BsWhatsapp, BsFacebook, BsTiktok,BsInstagram } from "react-icons/bs";
import {Widget} from "react-chat-Widget";


const spacing = 0.7;

export default function Footer (){
   return (<>
      <Divider
         css={{ position: 'absolute', inset: '0p', left: '0', mt: '$10' }}
      />
      <div style={{paddingTop:"40px"}}>
         <Grid.Container css={{ justifyContent: 'center' }}>
            <Button
               as={Link}
               href='https://www.facebook.com/intellomalia'
               target='_blank'
               css={{ marginRight: '19px'}}
               light
               auto
               color="primary"
               icon={<BsFacebook size={35} />}
            />
            <Button
               onClick={()=>{window.open('https://api.whatsapp.com/send?phone=237672676179');}}
               css={{ marginRight: '14px'}}
               light
               auto
               icon={<BsWhatsapp size={35} style={{color:"green"}}/>}
            />
            <Link href="https://vm.tiktok.com/ZMYopmaht/" target='_blank' 
                     css={{ marginRight: '13px'}}>
                  <Image
                     src="images/tiktok.png"
                     alt=""
                     objectFit="fill"
                     width={38}
                  />
            </Link>
            <Link href="" target='_blank'>
                  <Image
                     src="images/insta.png"
                     alt=""
                     objectFit="fill"
                     width={38}
                  />
            </Link>
         </Grid.Container>
      </div>
      <Grid.Container css={{ justifyContent: 'center' , marginTop:"50px"}}>
         <div style={{ marginRight:"28px"}}>
            <Link href='/terms' color='text'  underline> Terms of Use</Link>
         </div>
         <div style={{ marginRight:"28px"}}>
            <Link href='/privacy' color='text' underline> Privacy Policy</Link>
         </div>
         <div>
            <Link href='/contact' color='text' underline> Contact Us</Link>
         </div>
      </Grid.Container>
      <Grid.Container css={{ justifyContent: 'center' , marginTop:"40px"}}>
         <Text>2023 DisBusiness. All rights reserved.</Text>
      </Grid.Container>
      </>
   );
};