
import { Grid, Card, Text, Spacer, Table, Button } from "@nextui-org/react";
import {
  Chart as ChartJS, BarElement, CategoryScale,
  Legend, LinearScale, PointElement, Title,
  Tooltip, TimeScale, LineElement, Colors, TimeUnit
} from 'chart.js'

//import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { colors } from '@mui/material';
import { Nav } from "../../components/navbar/navbar";
import { Line } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "../../hook";
import { fetchUsers } from "../../redux/slices/user";
import { useEffect, useState } from "react";
import { groupUsersByDate } from "../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  Colors
);

export const options = {
  responsive: true,

  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};


export default function App() {

  const dispatch = useAppDispatch();
  const { users, loading } = useAppSelector(
    (state) => state.users
  );

  const [days, setDays] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [userTimeUnit, setUserTimeUnit] = useState("day");

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);
  useEffect(() => {
    setDays(groupUsersByDate(users, "day"));
    setWeeks(groupUsersByDate(users, "week"));
    setMonths(groupUsersByDate(users, "month"));
    setGroupedData(days);
  }, [users]);

  const changeGroup = (period) => {
    if(period == "day"){
      setGroupedData(days);
    }else if(period == "month"){
      setGroupedData(months);
    }else if(period == "week"){
      setGroupedData(weeks);
    }
    setUserTimeUnit(period);
  }
  const data = {
    //labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: [{
      label: "Nombres d'utilisateurs par jours",
      data: groupedData,
      borderColor: '#36A2EB',
      backgroundColor: '#9BD0F5',

    }]
  };
  const MockItem = ({ title, value }) => {
    return (
      <Card css={{ h: "$25", $$cardColor: '$colors$primary' }}>
        <Card.Body>
          <Text h6 size={14} color="white" css={{ m: 0 }} >
            {title}
          </Text>
          <Spacer y={0.5} />
          <Text h1 size={17} color="white" css={{ m: 5 }} >
            {value}
          </Text>
        </Card.Body>
      </Card>
    );
  };
  return (
    <>
      <Nav navLink="/dashboard" />
      <Grid.Container gap={1} justify="center">
        <Grid xs={6}>
          <MockItem title="Nombre d'utilisateurs" value={users.length} />
        </Grid>
        <Grid xs={6}>
          <MockItem title="Nombre de requettes" value="2415" />
        </Grid>
        <Grid xs={4}>
          <MockItem title="Transactions reussies" value="200" />
        </Grid>
        <Grid xs={4}>
          <MockItem title="Transactions échouées" value="2" />
        </Grid>
        <Grid xs={4}>
          <MockItem title="Transactions en cours" value="13" />
        </Grid>
        <Grid xs={9}>
          <MockItem title="Somme totale des transactions" value="122000 fcfa" />
        </Grid>
        <Grid xs={3}>
          <MockItem title="Titre 7" value="2 of 4" />
        </Grid>
        {/* Graphique */}
        <Button.Group>
          <Button onClick={(e) => changeGroup("day")} color="primary" >Jours</Button>
          <Button onClick={(e) => changeGroup("week")} light color="secondary">Semaines</Button>
          <Button onClick={(e) => changeGroup("month")} light  color="success">Mois</Button>
        </Button.Group>

        <Grid xs={6}>
          <Line options={{
            responsive: true,
            scales: {
              x: {
                type: "time",
                time: {
                  unit: userTimeUnit as TimeUnit
                }
              },
              y: {
                beginAtZero: true
              }
            }
          }} data={data} />
        </Grid>

      </Grid.Container></>
  );
}