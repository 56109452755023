import React, { useState } from 'react';
import {
   Modal,
   Input,
   Row,
   Checkbox,
   Button,
   Text,
   Navbar,
   Loading,
   useInput,
} from '@nextui-org/react';
import authService from '../../services/auth';
import { clearUser, setUserData } from '../../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../../hook';

export const LoginView = () => {
   const [visible, setVisible] = React.useState(false);
   const [loading, setLoading] = useState(false);
   const emailInput = useInput('');
   const passwordInput = useInput('');

   const dispatch = useAppDispatch();
   const handler = () => {
      if(user != null){
         // Logout
         dispatch(clearUser());
         return;
      }
       setVisible(true);
   }
   const { user } = useAppSelector(
      (state) => state.auth
  );
   const closeHandler = () => {
      setVisible(false);
      console.log('closed');
   };

   const handleLogin = (values) => {
      const body = {
         email: emailInput.value,
         password: passwordInput.value,
      };
      console.log(body);
      setLoading(true);
      authService
         .login(body)
         .then((res) => {
            const user = {
               role: res.data.user.role,
               token: res.data.access_token,
               email: res.data.user.email,
               id: res.data.user.id,
            };
            /*if (user.role === 'user') {
              
              return;
            }*/
            localStorage.setItem('token', res.data.access_token);
            dispatch(setUserData(user));
            if (user.role === 'admin') {
               //dispatch(fetchSettings());
            } else {
               //dispatch(fetchRestSettings());
            }
         })
         .finally(() => {
            setLoading(false);
            setVisible(false);
         });
   };

   return (
      <div>
         <Navbar.Link onClick={handler}>{user == null ? "Se connecter" : "Se déconnecter"} </Navbar.Link>
         <Modal
            closeButton
            blur
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
         >
            <Modal.Header>
               <Text id="modal-title" size={18}>
                  Bienvenue sur {' '}
                  <Text b size={18}>
                     Malia AI
                  </Text>
               </Text>
            </Modal.Header>
            <Modal.Body>
               <Input
                  {...emailInput.bindings}
                  clearable
                  bordered
                  fullWidth
                  aria-label='Email'
                  color="primary"
                  size="lg"
                  placeholder="Email"
               //   contentLeft={<Mail fill="currentColor" />}
               />
               <Input.Password
                  {...passwordInput.bindings}
                  clearable
                  bordered
                  fullWidth
                  aria-label='Password'
                  color="primary"
                  size="lg"
                  placeholder="Password"
               //   contentLeft={<Password fill="currentColor" />}
               />
               <Row justify="space-between">
                  <Checkbox aria-label='Remember me'>
                     <Text size={14}>Remember me</Text>
                  </Checkbox>
                  <Text size={14}>Forgot password?</Text>
               </Row>
            </Modal.Body>
            <Modal.Footer>
               <Button auto flat color="error" onPress={closeHandler} aria-label='Fermer'>
                  Fermer
               </Button>
               <Button auto onPress={handleLogin} aria-label='Se connected'  >
                  {loading ?  <Loading type="points" color="currentColor" size="sm" /> : "Connecter"}
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};
