import moment from "moment"

export function groupUsersByDate(elements, duration) {
    const formatted = elements.map(elem => {
      return { date: moment(elem.created_at).startOf(duration).format('YYYY-MM-DD'), count: 1 }
    })
  
    const dates = formatted.map(elem => elem.date)
    const uniqueDates = dates.filter((date, index) => dates.indexOf(date) === index)
  
    return uniqueDates.map(date => {
      const count = formatted.filter(elem => elem.date === date).reduce((count, elem) => count + elem.count, 0)
      return { x: Date.parse(date), y: count }
    })
  }