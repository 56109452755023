import React from "react";
import { Card, Grid, Text, Spacer, Input, Textarea, Button, } from "@nextui-org/react";
import { FiMail } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { BiUser } from 'react-icons/bi';
import { BsFillTelephoneFill, BsFacebook, BsGooglePlay } from 'react-icons/bs';
import { Nav } from "../../components/navbar/navbar";

const iconColors = "white";
// const buttonColors = "white";
const widthInput = "450px";

export default function Contact() {
    return (
        <>
            <Nav />
            <Spacer y={2} />

            {/* container */}
            <Grid.Container css={{ justifyContent: 'center' }}>
                <Card css={{ $$cardColor: '$colors$primary' }} >
                    <Card.Body>
                        {/* Bouttons de Contact */}

                        <Grid.Container css={{ justifyContent: "center", }}>
                            <Grid>
                                <Text h1 css={{ color: "white", align: "center" }}>Contactez Nous</Text>
                                <Text css={{ color: "white" }} >Remplissez le formulaire et notre équipe<br />
                                    et Notre équipe vous reviendra dans les 24 heures.</Text>
                                <Spacer y={2} />

                                <Button
                                    bordered css={{ color: "white" }}
                                    icon={<BsFillTelephoneFill />}
                                    auto ghost>
                                    +237 6 52 75 20 81
                                </Button>
                                <Spacer y={1.6} />
                                <Button
                                    bordered css={{ color: "white" }}
                                    icon={<FiMail />}
                                    auto ghost>
                                    info@disbusinessgroup.com
                                </Button>
                                <Spacer y={1.6} />
                                <Button
                                    bordered css={{ color: "white" }}
                                    icon={<GoLocation />}
                                    auto ghost>
                                    313 Rue Mandessi Bell, Douala
                                </Button>
                                <Spacer y={3} />
                                <Grid.Container css={{ justifyContent: 'space-around' }}>

                                    {/* Icones contacts   */}
                                    <div>
                                        <a href="https://www.facebook.com/profile.php?id=100091411962557">
                                            <BsFacebook color={iconColors} />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="https://play.google.com/store/apps/details?id=com.dis.malia" ><BsGooglePlay color={iconColors} /></a>

                                    </div>
                                    <Spacer y={2} />
                                </Grid.Container>


                            </Grid>
                            <Spacer x={6} />

                            {/* formulaire */}
                            <form>
                                <Grid>
                                    <Card variant="flat">
                                        <Card.Body>
                                            <Input
                                                bordered
                                                id="name"
                                                width={widthInput}
                                                label="Nom" placeholder="Entez votre nom"
                                                contentLeft={
                                                    <BiUser />
                                                }
                                            />
                                            <Spacer y={1.6} />
                                            <Input
                                                bordered
                                                width={widthInput}
                                                contentLeft={
                                                    <FiMail />
                                                }
                                                label="Mail" placeholder="Entez votre Mail" />
                                            <Spacer y={1.6} />
                                            <Textarea
                                                bordered
                                                width={widthInput}
                                                label="Message"
                                                placeholder="Message"
                                            />
                                            <Spacer y={2.6} />
                                            <Button css={{ width: "450px" }}>
                                                Envoyer
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                            </form>
                        </Grid.Container>
                    </Card.Body>
                </Card>
            </Grid.Container>
        </>
    )
}