import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/user';

export interface User{
    role: string, //"user" | "admin",
    registered_at: string,
    phone: string,
    promoCode: string,
    id: string,
    updated_at: string,
    created_at: string,
    credit: number, 
}

interface UserState {
  users: User[],
  loading: boolean,
  error: string | undefined
};
const initialState = {
  loading: false,
  users: [],
  error: '',
} as UserState;

export const fetchUsers = createAsyncThunk(
 'users/fetchUsers',
  (params) => {
    return userService.getAll(params).then((res) => res);
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      console.log("===> fetchUsers is pending with ", state);
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      console.log("===> fetchUsers is fulfiled with ", state);
      const { payload } = action;
      state.loading = false;
      state.users = payload.data;
      state.error = '';
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      console.log("===> fetchUsers is rejected with ", state);
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default userSlice.reducer;
