import { Button, Dropdown, Image, Link, Navbar, Switch, Text } from '@nextui-org/react';
import React, { useEffect } from 'react';
//import {ModalLogin} from '../modal';
//import {icons} from './icons';
import { MaliaLogo } from '../logo';
import { useTheme as useNextTheme } from 'next-themes';
import { useTheme } from '@nextui-org/react';
import { LoginView } from '../../views/auth/login';
import { useAppSelector } from '../../hook';

//import {GithubIcon} from '../icons/GithubIcon';

export const Nav = ({ navLink = "/" }) => {
   const { setTheme } = useNextTheme();
   const { isDark, type } = useTheme();
   const { user } = useAppSelector(
      (state) => state.auth
   );
   const collapseItems = [
      { href: "/", title: 'Home' },
      { href: '/privacy', title: 'Privacy Policy' },
      { href: '/terms', title: 'Terms' },
      { href: '/company', title: 'Company' },
      { href: '/contact', title: 'Contact us' },
   ];
   useEffect(() => {
      if (user != null) {
         console.log("User is not null, pushing transactions and dashboard");
         console.log(user);
         collapseItems.push({ href: '/transactions', title: 'Transactions' });
         collapseItems.push({ href: '/dashboard', title: 'Dashboard' });
      }
   }, [user])
   return (
      <Navbar
         variant={"sticky"}
         isBordered
         css={{

            '& .nextui-navbar-container': {
               background: '$background',
               borderBottom: 'none',
            },
         }}
      >
         <Navbar.Brand>
            <Navbar.Toggle aria-label="toggle navigation" showIn="xs" />
            { /* <MaliaLogo /> */}
            <Image
               alt="Logo"
               src="images/logo.png"
               width={45}
            />
            <Text b color="inherit" hideIn="xs">
               {'     '} Malia AI
            </Text>
            <Navbar.Content
               hideIn="sm"
               css={{
                  pl: '6rem',
               }}
            >
               <Navbar.Link isActive={navLink == "/"} href="/">
                  Home
               </Navbar.Link>
               <Navbar.Link isActive={navLink == "/privacy"} href="/privacy">Privacy Policy</Navbar.Link>
               <Navbar.Link isActive={navLink == "/terms"} href="/terms">Terms</Navbar.Link>
               <Navbar.Link isActive={navLink == "/company"} href="/company">Company</Navbar.Link>
               <Navbar.Link isActive={navLink == "/contact"} href="/contact">Contact us</Navbar.Link>
               {user != null ? <Navbar.Link isActive={navLink == "/transactions"} href="/transactions">Transactions</Navbar.Link> : <></>}
               {user != null ? <Navbar.Link isActive={navLink == "/users"} href="/users">Users</Navbar.Link> : <></>}
               {user != null ? <Navbar.Link isActive={navLink == "/dashboard"} href="/dashboard">Dashboard</Navbar.Link> : <></>}
            </Navbar.Content>
         </Navbar.Brand>

         <Navbar.Collapse>
            {collapseItems.map((item, index) => (
               <Navbar.CollapseItem key={item.href}>
                  <Link
                     color="inherit"
                     css={{
                        minWidth: '100%',
                     }}
                     href={`${item.href}`}
                  >
                     {item.title}
                  </Link>
               </Navbar.CollapseItem>
            ))}
            {user != null ? <><Navbar.CollapseItem key="/transactions">
               <Link
                  color="inherit"
                  css={{
                     minWidth: '100%',
                  }}
                  href={`/transactions`}
               >
                  Transactions
               </Link>
            </Navbar.CollapseItem>
               <Navbar.CollapseItem key="/dashboard">
                  <Link
                     color="inherit"
                     css={{
                        minWidth: '100%',
                     }}
                     href={`/dashboard`}
                  >
                     Dashboard
                  </Link>
               </Navbar.CollapseItem>
               <Navbar.CollapseItem key="/users">
                  <Link
                     color="inherit"
                     css={{
                        minWidth: '100%',
                     }}
                     href={`/users`}
                  >
                     Users
                  </Link>
               </Navbar.CollapseItem></>
               : <></>}
            <Navbar.CollapseItem>
               <Switch
                  checked={isDark}
                  onChange={(e) =>
                     setTheme(e.target.checked ? 'dark' : 'light')
                  }
               />
            </Navbar.CollapseItem>
         </Navbar.Collapse>
         <Navbar.Content>
            <LoginView />

            <Navbar.Item>
               <Button auto flat href="#">
                  S'inscrire
               </Button>
            </Navbar.Item>
            <Navbar.Item hideIn={'xs'}>
               <Link
                  color="inherit"
                  css={{
                     minWidth: '100%',
                  }}
                  target="_blank"
                  href="#"
               >
                  { /*   <GithubIcon /> */}
               </Link>
            </Navbar.Item>
            <Navbar.Item hideIn={'xs'}>
               <Switch
                  checked={isDark}
                  onChange={(e) =>
                     setTheme(e.target.checked ? 'dark' : 'light')
                  }
               />
            </Navbar.Item>
         </Navbar.Content>
      </Navbar>
   );
};
