import payment from './slices/payment';
import auth from './slices/auth';
import users from './slices/user';


const rootReducer = {
    payment,
    auth,
    users
};
export default rootReducer;