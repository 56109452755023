import api from './axios_service';

const paymentService = {
  getAll: (params) => api.get('/v1/dashboard/payments', { params }),
  getById: (id: string, params: { [key: string]: any }) =>
    api.get(`dashboard/admin/payments/${id}`, { params }),
  update: (id: string, data: { [key: string]: any }) => api.put(`dashboard/admin/payments/${id}`, data),
  setActive: (id: string) =>
    api.post(`dashboard/admin/payments/${id}/active/status`, {}),
};

export default paymentService;
