import { Text, Container, Spacer, useModal } from "@nextui-org/react";
import { Nav } from "../../components/navbar/navbar";
import { styled } from '@nextui-org/react';
import { Flex } from "../../components/layout/flex";

import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

export default  function Utilisateurs() {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("300px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const columns = [
    {name: "id", label:"ID"},
    {name: "phoneNumber", label:"Numéro de Téléphone"},
    {name: "credit", label: "Crédit", type:"number"},
    {name: "promoCode", label: "Code Promo"},
    {name: "creationdate", label: "Date de Création"},
    {name: "role", label: "Role "},
    {name: "lastConnexion", label: "Dernière Connexion "},
    {name: "active", label: "Active ", type:"number"},
    
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const data = [
  ];

  return (
    <>
      <Nav navLink="/users" />
      <Container justify="center">
        <Flex
          css={{
            gap: '$15',
            pt: '$4',
            }}
            wrap={'wrap'}
        >
        <Text>Total Utilisateur : ...</Text>
                
        </Flex>
        <Spacer y={1.5} />
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={createTheme()}>
            <MUIDataTable
              title={"Liste des Utilisateurs"}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </Container>
    </>
  );
}

