import { Grid, Text, Link, Image, Card, Input, Button, Divider } from '@nextui-org/react';
import React, { useState } from 'react';
import { BsFillArrowDownCircleFill, BsFillLightningChargeFill } from "react-icons/bs";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import Spin from 'react-reveal/Spin';
import Swing from 'react-reveal/Swing';
import Fade from 'react-reveal/Fade';


export default function FeatureView() {
    const [text1, setText] = useState('Iphone')
    const [textbutton, setText3] = useState('Download for Iphone')
    const [text2, setText2] = useState('The power of ChatGPT is in your pocket. Malia is available on iPhones with iOS 14+.')
    const [image, setImage] = useState('images/screenshot9.png');

    const handleClick = (image, text1, text2, textbutton) => {
        setImage(image)
        setText(text1)
        setText3(textbutton)
        setText2(text2)
    }

    return (
        <>
            <Text
                  h2
                  css={{
                     marginTop:'60px',
                     marginBottom:'10px',
                     textAlign: 'center',
                  }}
                  color="primary"
               >
                  Explorer
               </Text>
               <Grid.Container css={{ justifyContent:'space-around', marginBottom: '90px' }}>
                <div>
                <Spin>
                    <Card isHoverable isPressable variant="bordered" color='primary' 
                    css={{width:'260px', height:'230px', backgroundColor:'#1074fc', paddingTop:'20px'}}>
                        <Image
                            src='images/code.png'
                            alt="/"
                            width={58}
                            
                        />
                        <Text  css={{textAlign:'center', color:'white', marginTop:'10px', marginBottom:'10px'}}>
                            <h3  style={{color:'white'}}>Ecrire du Code</h3>
                            Ecrivez des pages web<br/>et des applications<br/>simples dans 
                            différents language
                        </Text>
                    </Card>
                </Spin>
                </div>
                <div>
                <Swing>
                    <Card isHoverable isPressable variant="bordered" color='primary' 
                    css={{width:'260px', height:'230px', backgroundColor:'#1074fc', paddingTop:'20px'}}>
                        <Image
                            src='images/anniverssaire.png'
                            alt="/"
                            width={58}
                            
                        />
                        <Text  css={{textAlign:'center', color:'white', marginTop:'10px', marginBottom:'10px'}}>
                            <h3  style={{color:'white'}}>Anniverssaire</h3>
                            Envoyez un souhait<br/>d'anniverssaires sincère<br/>pour vos proches
                        </Text>
                    </Card>
                </Swing>
                </div>
                <div>
                <Fade top big>
                    <Card isHoverable isPressable variant="bordered" color='primary' 
                    css={{width:'260px', height:'230px', backgroundColor:'#1074fc', paddingTop:'20px'}}>
                        <Image
                            src='images/gen_nom.png'
                            alt="/"
                            width={58}
                            
                        />
                        <Text  css={{textAlign:'center', color:'white', marginTop:'10px', marginBottom:'10px'}}>
                            <h3  style={{color:'white'}}>Générateur de Nom</h3>
                            Trouver un bon Nom<br/>pour votre marque ou<br/>votre produit
                        </Text>
                    </Card>
                </Fade>
                </div>
                <div>
                <Swing>
                    <Card isHoverable isPressable variant="bordered" color='primary' 
                    css={{width:'260px', height:'230px', backgroundColor:'#1074fc', paddingTop:'20px'}}>
                        <Image
                            src='images/story.png'
                            alt="/"
                            width={58}
                            
                        />
                        <Text  css={{textAlign:'center', color:'white', marginTop:'10px', marginBottom:'10px'}}>
                            <h3  style={{color:'white'}}>Histoire</h3>
                            Générez une Histoire à<br/>partir d'un sujet donné
                        </Text>
                    </Card>
                </Swing>
                </div>
                <div>
                <Spin>
                    <Card isHoverable isPressable variant="bordered" color='primary' 
                    css={{width:'260px', height:'230px', backgroundColor:'#1074fc', paddingTop:'20px'}}>
                        <Image
                            src='images/paroles.png'
                            alt="/"
                            width={58}
                            
                        />
                        <Text  css={{textAlign:'center', color:'white', marginTop:'10px', marginBottom:'10px'}}>
                            <h3  style={{color:'white'}}>Paroles</h3>
                            Générez les paroles<br/>d'une chanson pour n'importe<br/>
                            quel genre musical
                        </Text>
                    </Card>
                </Spin>
                </div>
            </Grid.Container>
            <div >
                <Text h1 css={{ textAlign: 'center'}}>Available on</Text>
                <Grid.Container css={{ justifyContent: 'center' }}>
                    <Button
                        onClick={() => handleClick('images/screenshot9.png', 'Iphone', 'The power of ChatGPT is in your pocket. Malia is available on \niPhones with iOS 14+.', 'Download for Iphone')}
                        css={{ marginRight: '14px' }}
                        auto
                        color="warning"
                        icon={<AiFillApple size={30} />}
                    />
                    <Button
                        onClick={() => handleClick('images/screenshot3.jpeg', 'Android', 'The power of Malia is in your pocket. Malia is available on all Android devices.', 'Download for Android')}
                        auto
                        color="secondary"
                        icon={<AiFillAndroid size={30} />}
                    />
                </Grid.Container>

            </div>
            <Grid.Container css={{ justifyContent: 'center' }}>
                <Card css={{ mw: "500px", marginTop: '30px', paddingBottom: '60px' }}>
                    <Grid.Container css={{ justifyContent: 'center' }}>
                        <div>
                            <div>
                                <Card
                                    css={{
                                        mw: "500px", marginTop: '30px', width: '300px',
                                        height: '300px',
                                        borderRadius: '100%',
                                        marginRight: 'auto',
                                        marginLeft: 'auto'
                                    }}>
                                    <Image
                                        src={image}
                                        alt=""
                                        width={162}
                                    />
                                </Card>
                            </div>

                            <div>
                                <Text h1 css={{ textAlign: 'center' }}> {text1}</Text>
                                <Text size={20} css={{ textAlign: 'center' }}>
                                    {text2}
                                </Text>
                            </div>
                            <div>
                                <Button css={{
                                    marginTop: '30px', marginRight: 'auto',
                                    marginLeft: 'auto'
                                }} >{textbutton}</Button>
                            </div>
                        </div>
                    </Grid.Container>
                </Card>
            </Grid.Container>
        </>);
}