import { Grid, Text, Link, Image, Card, Input, Button, Divider } from '@nextui-org/react';
import React, { useState } from 'react';
import { BsFillArrowDownCircleFill, BsFillLightningChargeFill } from "react-icons/bs";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";

import { BsWechat, BsSend } from 'react-icons/bs';
import { FcDocument, FcIdea } from 'react-icons/fc';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { HiOutlineArrowNarrowRight, HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { Flex } from '../../components/layout/flex';
import { Box } from '../../components/layout/box';
import { CheckIcon } from '../../components/icons/CheckIcon';

export default function TopView() {
   return (<>
      <Flex
         css={{
            'gap': '$3',
            'px': '$6',
            'flexDirection': 'column',
            'alignContent': 'center',
            'justifyContent': 'center',
            'alignItems': 'center',
            'width': '100%',
            '@sm': {
               flexDirection: 'row',
               mt: '$20',
            },
         }}
         justify={'center'}
      >
         <Box
            css={{
               pt: '$13',

               display: 'flex',
               flexDirection: 'column',
               gap: '$5',
            }}
         >
            <Box
               css={{
                  maxWidth: '600px',
               }}
            >
               <Text
                  h1
                  css={{
                     display: 'inline',
                  }}
               >
                  Malia AI: Une Intelligence{' '}
               </Text>
               <Text
                  h1
                  css={{
                     display: 'inline',
                  }}
               >
                  Artificielle à {' '}
               </Text>
               <Text
                  h1
                  css={{
                     display: 'inline',
                  }}
                  color="primary"
               >
                  porter de main
               </Text>
            </Box>

            <Text
               css={{
                  color: '$accents8',
                  maxWidth: '450px',
               }}
               size={'$lg'}
               span
            >
               MALIA est un chatbot IA révolutionnaire
               proposé par DIS business Group et basé sur GPT-3.5 Turbo.
            </Text>

            <Flex
               css={{
                  gap: '$8',
                  pt: '$4',
               }}
               wrap={'wrap'}
            >
               <Input placeholder="Votre numéro de téléphone" size="lg" />
               <Button>S'inscrire</Button>
            </Flex>
            <Flex
               wrap={'wrap'}
               css={{
                  'gap': '$8',
                  'py': '$7',
                  '@sm': {
                     py: '$4',
                  },
               }}
            >
               <Flex
                  css={{
                     color: '$accents7',
                     alignItems: 'center',
                  }}
               >
                  <CheckIcon /> Pas besoin de payer.
               </Flex>
               <Flex
                  css={{
                     color: '$accents7',
                     alignItems: 'center',
                  }}
               >
                  <CheckIcon /> 3 Requêtes gratuites
               </Flex>
               <Flex
                  css={{
                     color: '$accents7',
                     alignItems: 'center',
                  }}
               >
                  <CheckIcon /> Annulez à tout moment.
               </Flex>

            </Flex>
            <Flex
               css={{
                  gap: '$15',
                  pt: '$4',
               }}
               wrap={'wrap'}
            >
               <Link href="https://apps.apple.com/app/id6446824169?platform=iphone" target='_blank' >
                  <Image
                     src="images/app-store-badge.png"
                     alt=""
                     width={160}
                     objectFit="fill"
                  />
               </Link>

               <Link href="https://play.google.com/store/apps/details?id=com.dis.malia" target='_blank'>
                  <Image
                     src="images/google-play-badge.png"
                     alt=""
                     objectFit="fill"
                     width={170}
                  />
               </Link>

            </Flex>
         </Box>
         <Box
            css={{
               '& img': {
                  width: '375px',
                  objectFit: 'contain',
               },
            }}
         >
            <img src="images/top.png" />
         </Box>
      </Flex>
      <Divider
         css={{ position: 'absolute', inset: '0p', left: '0', mt: '$10' }}
      />
   </>);
   /*return (
       <>
           <Text size={50} css={{ textAlign: 'center' }}>
               Malia: Your AI Assistant
           </Text>
           <Text size={30} css={{ textAlign: 'center' }}>
               MALIA is a revolutionary AI chatbot<br />
               powered by Disbusiness & GPT-3.5 Turbo.
           </Text>
           <Grid.Container css={{ justifyContent: 'center' }}>
               <Link href="https://apps.apple.com/app/id6446824169?platform=iphone" target='_blank' css={{ marginRight: '5px' }}>
                   <Image
                       src="images/app-store-badge.png"
                       alt=""
                       width={150}
                   />
               </Link>
               <Link href="https://play.google.com/store/apps/details?id=com.dis.malia" target='_blank'>
                   <Image
                       src="images/google-play-badge.png"
                       alt=""
                       width={162}
                   />
               </Link>
           </Grid.Container>

           <div style={{ paddingLeft: 'auto', paddingRight: 'auto' }}>
               <Card css={{ mw: "1000px", marginTop: '70px', marginLeft: 'auto', marginRight: 'auto' }}>
                   <Grid.Container css={{ justifyContent: 'center', marginTop: '40px' }}>
                       <div style={{ marginRight: '10px' }}>
                           <Image
                               src="images/screenshot9.png"
                               alt=""
                               width={162}
                           />
                       </div>
                       <div style={{ marginRight: '10px' }}>
                           <Image
                               src="images/screenshot9.png"
                               alt=""
                               width={162}
                           />
                       </div>
                       <div >
                           <Image
                               src="images/screenshot9.png"
                               alt=""
                               width={162}
                           />
                       </div>
                   </Grid.Container>

                   <Input
                       color='primary'
                       size='xl'
                       css={{ marginTop: '40px', marginRight: 'auto', marginLeft: 'auto' }}
                       bordered
                       placeholder='Enter your email address'
                       contentRight={<Button size="xs">Try for Free</Button>}
                   />

                   <Grid.Container css={{ justifyContent: 'center' }}>
                       <div style={{ marginRight: '40px' }}>
                           <Grid.Container css={{ marginTop: '40px', marginBottom: '30px' }}>
                               <div>
                                   <BsFillLightningChargeFill size={32} style={{ marginTop: '30px', marginRight: '7px' }} />
                               </div>
                               <div>
                                   <Text size={20} >
                                       Trusted by thousands of<br /> users around the world
                                   </Text>
                               </div>
                           </Grid.Container>
                       </div>
                       <div>
                           <Grid.Container css={{ marginTop: '40px' }}>
                               <div>
                                   <BsFillArrowDownCircleFill size={32} style={{ marginTop: '30px', marginRight: '7px' }} />
                               </div>
                               <div>
                                   <Text size={20} >
                                       Available for Web, iPhone, <br />Apple Watch, macOS, Android
                                   </Text>
                               </div>
                           </Grid.Container>
                       </div>
                   </Grid.Container>
               </Card>
           </div>
       </>);*/
}