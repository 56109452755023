import api from './axios_service';

const userService = {
  getAll: (params) => api.get('/v1/dashboard/users', { params }),
  getById: (id: string, params: { [key: string]: any }) =>
    api.get(`dashboard/admin/users/${id}`, { params }),
  update: (id: string, data: { [key: string]: any }) => api.put(`dashboard/admin/payments/${id}`, data),
  setActive: (id: string) =>
    api.post(`dashboard/admin/payments/${id}/active/status`, {}),
};

export default userService;
