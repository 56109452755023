import type { NextPage } from "next";
import React from "react";
import TopView from "./top";
import { Nav } from "../../components/navbar/navbar";
import { Box } from "../../components/layout/box";
import { Layout } from "../../components/layout";
import FeatureView from "./feature";
import Footer from "../../components/footer";

export const HomeView: NextPage = () => {
  return (
    <Layout>
      <Nav />
      <Box as="main">
        <TopView />
        <FeatureView />
        <Footer/>
      </Box>
    </Layout>
  );
};
