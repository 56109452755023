import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import paymentService from '../../services/payment';

interface PaymentState {
  payments: [],
  loading: boolean,
  error: string | undefined
};
const initialState = {
  loading: false,
  payments: [],
  error: '',
} as PaymentState;

export const fetchPayments = createAsyncThunk(
 'payment/fetchPayments',
  (params) => {
    return paymentService.getAll(params).then((res) => res);
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPayments.pending, (state) => {
      console.log("===> fetchPayments is pending with ", state);
      state.loading = true;
    });
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      console.log("===> fetchPayments is fulfiled with ", state);
      const { payload } = action;
      state.loading = false;
      state.payments = payload.data;
      state.error = '';
    });
    builder.addCase(fetchPayments.rejected, (state, action) => {
      console.log("===> fetchPayments is rejected with ", state);
      state.loading = false;
      state.payments = [];
      state.error = action.error.message;
    });
  },
  reducers: {}
});

export default paymentSlice.reducer;
