import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, NextUIProvider } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import './globals.css';
import Transactions from "./views/transactions";
import { HomeView } from './views/home';
import Dashboard from './views/dashboard';
import PrivacyView from './views/company/privacy';
import TermsView from './views/company/terms';
import Contact from './views/contact';
import ListOfUsers from './views/users';

const lightTheme = createTheme({
  type: 'light',
  theme: {
    colors: {},
  },
});

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: {},
  },
});


function App() {
  return (
    <NextThemesProvider
      defaultTheme="system"
      attribute="class"
      value={{
        light: lightTheme.className,
        dark: darkTheme.className,
      }}
    >
      <NextUIProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/privacy" element={<PrivacyView />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<TermsView />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/users' element={<ListOfUsers />} />
          </Routes>
        </BrowserRouter>
      </NextUIProvider>
    </NextThemesProvider>
  );
}

export default App;
