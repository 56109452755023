import { Table, Row, Col, Tooltip, Text, Container, Input, Spacer, useModal, Grid } from "@nextui-org/react";

import { BiEdit, BiRefresh } from "react-icons/bi";
import { FiDelete } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchPayments } from '../../redux/slices/payment';
import { useAppDispatch, useAppSelector } from "../../hook";
import { IconButton } from "@mui/material";
import reportWebVitals from '../../reportWebVitals';
import { Nav } from "../../components/navbar/navbar";
import { styled } from '@nextui-org/react';

import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Flex } from "../../components/layout/flex";


const StyledBadge = styled('span', {
    display: 'inline-block',
    textTransform: 'uppercase',
    margin: '0 2px',
    fontSize: '15px',
    fontWeight: '$bold',
    borderRadius: '14px',
    letterSpacing: '0.6px',
    lineHeight: 1,
    boxShadow: '1px 2px 5px 0px rgb(0 0 0 / 5%)',
    alignItems: 'center',
    alignSelf: 'center',
    color: '$white',
    variants: {
      type: {
        SUCCESSFUL: {
          bg: '$successLight',
          color: '$successLightContrast'
        },
        FAILED: {
          bg: '$errorLight',
          color: '$errorLightContrast'
        },
        PENDING: {
          bg: '$warningLight',
          color: '$warningLightContrast'
        },
        CANCELLED: {
            bg: '$warningLight',
            color: '$warningLightContrast'
          },
        EXPIRED: {
            bg: '$errorLight',
            color: '$errorLightContrast'
          },
      }
    },
    defaultVariants: {
      type: 'active'
    }
  });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
  });


export default function Transactions() {
    const [responsive, setResponsive] = useState("simple");
    const [tableBodyHeight, setTableBodyHeight] = useState("100%");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);
    const { setVisible, bindings } = useModal();

    const dispatch = useAppDispatch();
    const { payments, loading } = useAppSelector(
        (state) => state.payment
    );

    const [totaux, setTotaux] = useState({});

    const options = {
        search: searchBtn,
        download: downloadBtn,
        print: printBtn,
        viewColumns: viewColumnBtn,
        filter: filterBtn,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        onTableChange: (action, state) => {
        console.log(action);
        console.dir(state);
        }
     };


    useEffect(() => {
        dispatch(fetchPayments());
    }, []);

    useEffect(() => {
        // calculer les totaux
        const v = payments.reduce((total, item) => {
            return {
                ...total,
                [item["status"]]: total[item["status"]] + 1,
                "AMOUNT": total["AMOUNT"] + (item["status"] == "SUCCESSFUL" ? item["amount"] : 0)
            };
        }, { "SUCCESSFUL": 0, "FAILED": 0, "PENDING": 0, "AMOUNT": 0 });
        console.log("Les totaux", v);
        setTotaux(v);
    }, [payments])

    const columns = [
      {name: "id", label:"ID"},
      {name: "description", label:"Description"},
      {name: "type", label: "Channel"},
      {name: "amount", label: "Montant"},
      {name: "status", label: "Status",
         options: {
           customBodyRender: val => {
             // val2 = val
             return (
               <div style={{ position: "relative", height: "20px" }}>
                 <div>
                   <div><StyledBadge type={val}>{val}</StyledBadge></div>
                 </div>
               </div>
             );
           }
         }
       },
      {name: "phoneNumber", label: "Phone number"},
      {name: "message", label: "Message",
         options: {
         //   setCellProps: () => ({ style: { overflow: "hidden",
         //   textOverflow: "ellipsis", width:'30%', color: "#00FF00"} }),
           customBodyRender: val => {
            const parentStyle = {
                position : "absolute" as any,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  boxSizing: "border-box" as any,
                  display: "block",
                  width: "100%",
              
            };
            const cellStyle = {
              boxSizing: "border-box" as any,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap" as any,
  
            };
            // val3 = val
            return (
              <div style={{ position: "relative", height: "20px" }}>
                <div style={parentStyle}>
                  <div style={cellStyle}>{val}</div>
                </div>
              </div>
            );
          }
         }
       },
       {name: "created_at", label: "Date", 
        options: {
            customBodyRender: (val) => {
                const d = new Date(val);
                return (
                    <div>{d.toLocaleDateString()}</div>
                );
            }
        }},
        {name: "Details", label: "Details",
        options: {
            empty:true,
            customBodyRender: (val) => {
                const d = new Date(val);
                return (
                    <div><FiEye/></div>
                );
            }
        }},
    ];

    return (
        <>
            <Nav navLink="/transactions" />
            <Container justify="center">
            <Grid.Container>
              <Grid xs={6}>
                <Flex
                css={{
                    gap: '$15',
                    pt: '$4',
                }}
                wrap={'wrap'}
              >  
                  <Text>Montant Successful: {totaux["SUCCESSFUL"]} </Text>
                  <Text>Montant Failed: {totaux["FAILED"]}</Text>
                  <Text>Montant Pending: {totaux["PENDING"]}</Text>
                  <Text>Nombre de transactions: {payments.length}</Text>
                  <Text>Montant Total : {totaux["AMOUNT"]} FCFA</Text>
                  </Flex>
              </Grid>
              <Grid xs={6}>
                  <BiRefresh/>
              </Grid>
            
            </Grid.Container>
            <Spacer y={1.5} />
            <CacheProvider value={muiCache}>
                <ThemeProvider theme={createTheme()}>

                    <MUIDataTable
                    title={"Liste des Transactions"}
                    data={payments}
                    columns={columns}
                    options={options}
                    />
                </ThemeProvider>
              </CacheProvider>
            </Container>
        </>
    );
}